<template>
  <div>
    <CustomTable
      :headers="headers"
      :items="items"
      item-key="id"
      :loading="loading"
      :options.sync="options"
      :sortNameParam="sortNameParam"
      :sortSetParam="sortSetParam"
      :selectedFilters="selectedFilters"
      :isPermission="permission.readAll"
      :disable-sort="!permission.readAll"
      @changeSortParams="sortAction"
      @filterAction="filterAction"
    >
      <template #item.details="{item}">
        <v-hover v-slot="{hover}">
          <v-icon :color="hover ? 'red' : ''" size="20" @click="$emit('action', {item, type: 'delete'})">
            mdi-trash-can-outline
          </v-icon>
        </v-hover>
        <v-hover v-if="permission.read" v-slot="{hover}" class="ml-5">
          <v-icon :color="hover ? 'red' : ''" size="20" @click="$emit('action', {item, type: 'details'})">
            mdi-pencil
          </v-icon>
        </v-hover>
      </template>
    </CustomTable>
    <Pagination
      v-model="dataPagination.pageNumber"
      :totalPages="dataPagination.totalPages"
      :disabled="loading"
      @input="setPage"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  name: 'ClientsTable',
  props: {
    selectedFilters: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    options: {},
    pageParams: {
      page: 1,
    },
    sortNameParam: 'id',
    sortSetParam: 'desc',
  }),
  computed: {
    ...mapState({
      items: (state) => state.user.items,
      dataPagination: (state) => state.user.dataPagination,
      loading: (state) => state.user.loading,
    }),
    ...mapGetters({
      permission: 'user/permission',
    }),
    params: {
      get() {
        return {
          sort: this.sortNameParam + ',' + this.sortSetParam,
          size: 20,
          page: 0,
        }
      },
      set(val) {
        return val
      },
    },
    headers() {
      return [
        {
          text: '#',
          value: 'id',
          align: 'start',
        },
        {
          text: this.$t('table.email'),
          value: 'email',
          align: 'center',
          isFilter: true,
        },
        {
          text: this.$t('table.company'),
          value: 'company',
          align: 'center',
          isFilter: true,
        },
        {
          text: this.$t('table.phone'),
          value: 'phone',
          align: 'center',
          isFilter: true,
        },
        {
          text: this.$t('table.contractNumber'),
          value: 'contractNumber',
          align: 'center',
          isFilter: true,
        },
        {
          text: this.$t('table.details'),
          value: 'details',
          align: 'end',
          sortable: false,
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getUsers: 'user/GET_ITEMS',
    }),
    async loadData(data, isStart) {
      if (isStart) this.pageParams.page = 1
      const body = {
        ...this.params,
        ...data,
        page: this.pageParams.page - 1,
      }
      await this.getUsers(body)
    },
    sortAction({sortNameParam = 'id', sortSetParam = 'desc'}) {
      this.sortNameParam = sortNameParam
      this.sortSetParam = sortSetParam
      if (this.permission.readAll) this.loadData()
    },
    filterAction(item) {
      this.$emit('filter', item)
    },
    setPage(page) {
      if (page !== this.pageParams.page) {
        this.pageParams.page = page
        this.loadData(this.params)
      }
    },
  },
}
</script>
