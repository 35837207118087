<template>
  <div>
    <ActionButton
      v-if="permission.new"
      class="mb-15"
      icon="mdi-account-plus"
      @click="newHandler('NewClient')"
    >
      <span class="d-none d-sm-block">{{ $t('button.newUser') }}</span>
    </ActionButton>
    <v-slide-y-transition hide-on-leave>
      <CustomTableFilter
        v-if="isShowFilter"
        :params="params"
        :selectedFilters="selectedFilters"
        @add="addParamHandler"
        @clear="clearHandler"
        @delete="deleteParamHandler"
      />
    </v-slide-y-transition>
    <ClientsTable
      ref="clientsTable"
      :selectedFilters="selectedFilters"
      @action="actionHandler"
      @filter="addFilterHandler"
    />
    <CustomDialog v-model="isActionDialog" minHeight="250">
      <div class="text-center mt-15">
        <h3>{{ $t('dialog.deleteTitle') }}</h3>
        <ActionButton class="my-15" @click="deleteHandler">{{ $t('button.confirm') }}</ActionButton>
      </div>
    </CustomDialog>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import ClientsTable from '@/components/table/universal/ClientsTable'
import substringLength from '@/helpers/substringLength'
import {newHandler} from '@/helpers/handler/newHandler'

export default {
  name: 'Clients',
  components: {ClientsTable},
  data: () => ({
    isShowFilter: false,
    params: {},
    selectedFilters: [],
    actionItem: null,
    isActionDialog: false,
  }),
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isManager: 'auth/isManager',
      permission: 'user/permission',
    }),
  },
  methods: {
    substringLength,
    newHandler,
    async loadData() {
      let params
      this.selectedFilters.forEach((a) => {
        params = Object.assign({[a.name]: a.value}, params)
      })
      await this.$refs.clientsTable.loadData(params, true)
    },
    actionHandler({item, type}) {
      this.actionItem = item
      let name = ''
      switch (type) {
        case 'details':
          switch (true) {
            case this.isAdmin:
              name = 'AdminClientDetail'
              break
            case this.isManager:
              name = 'ManagerClientDetail'
              break
            default:
              name = 'AdvertiserClientDetail'
          }
          this.$router.push({name: name, params: {id: item.id}})
          break
        case 'delete':
          console.log(item, 'item')
          console.log(type, 'type')
          this.showActionDialog()
          break
        default:
          break
      }
    },
    addFilterHandler(item) {
      this.isShowFilter = true
      this.params = {
        name: item.value,
        value: '',
        placeholder: item.placeholder ?? item.value,
      }
    },
    addParamHandler() {
      const isDuplicate = this.selectedFilters.some((a) => a.name === this.params.name)
      if (!isDuplicate) {
        this.selectedFilters = [...this.selectedFilters, {...this.params}]
      } else {
        let index = this.selectedFilters.findIndex((a) => a.name === this.params.name)
        Object.assign(this.selectedFilters[index], this.params)
      }
      this.loadData()
    },
    deleteParamHandler(item) {
      this.selectedFilters = this.selectedFilters.filter((a) => a.name !== item.name)
      if (this.selectedFilters.length === 0) this.isShowFilter = false
      this.loadData()
    },
    clearHandler() {
      this.isShowFilter = false
      this.params = {}
      this.selectedFilters = []
      this.loadData()
    },
    showActionDialog() {
      this.isActionDialog = true
    },
    deleteHandler() {
      alert('test delete')
      const {id} = this.actionItem
      console.log(id, 'deleteHandler')
      // await this.deleteTarget(id)
      this.isActionDialog = false
    },
  },
}
</script>
