export function newHandler(name) {
  switch (true) {
    case this.isAdmin:
      this.$router.push({name: `Admin${name}`})
      break
    case this.isManager:
      this.$router.push({name: `Manager${name}`})
      break
    default:
      this.$router.push({name: `Advertiser${name}`})
  }
}
